@use '../util' as *;
// https://codepen.io/SaraSoueidan/pen/jpBbrq/?editors=1100

.toggle {
    display: grid;
    grid-template-columns: 1fr 3rem;
    border: none;
    gap: rem(12);
    margin: 0;

    label {
        font-size: rem(14);
        font-weight: 700;
        color: var(--toggle);

        &[for='dark'] {
            grid-column: 1/2;
            justify-self: end;
            line-height: rem(24);
        }

        &[for='light'] {
            grid-column: 3/4;
            line-height: rem(24);
            margin-right: rem(13);
        }
    }

    &__wrapper {
        position: relative;
        height: rem(24);
    }

    input[type='radio'] {
        margin: 0 rem(-2) 0 rem(-2);
        opacity: 0;
        width: rem(24);
        height: rem(24);

    }

    &__background {
        display: block;
        height: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        border-radius: rem(12);
        background: var(--toggle-bg);
        pointer-events: none;
    }

    &__button {
        position: absolute;
        left: rem(3);
        top: rem(3);
        right: 100%;
        width: rem(18);
        height: rem(18);
        border-radius: 50%;
        background-color: var(--toggle-button);
        transition: all 150ms ease-in-out;
    }

    #light:checked~.toggle__button {
        left: calc(100% - 21px);
        right: 3px;
    }

    #system:checked~.toggle__button {
        left: 50%;
        right: auto;
        transform: translate(-50%);
    }
}